<template>
  <div class="FormWrap">
    <!-- <mia-navback :isShowBack="false" :isShowSave="true" @submit="EditCompany" /> -->
    <div class="content" v-loading="loading">
      <mia-Card title="基础信息">
        <div style="margin-left: 4px" slot="content">
          <el-form
            :rules="rules"
            ref="InformationForm"
            :model="form"
            :inline="true"
          >
            <el-row>
              <el-col :span="2" class="form-label">
                <span>商户编号</span>
              </el-col>
              <el-col :span="8">
                <el-input
                  v-model="form.code"
                  :disabled="true"
                  placeholder=""
                ></el-input>
              </el-col>
              <el-col :span="2" class="form-label">
                <span>商户名称</span>
              </el-col>
              <el-col :span="8">
                <el-input
                  v-model="form.name"
                  :disabled="true"
                  placeholder="填写营业执照上的名称"
                ></el-input>
              </el-col>
              <el-col :span="2">
                <el-button type="primary" @click="changeName()">修改</el-button>
              </el-col>
            </el-row>
            <!-- <el-form-item label="商户编号" prop="name">
              <el-col :span="12">
                <el-input
                  v-model="form.code"
                  :disabled="true"
                  placeholder=""
                ></el-input>
              </el-col>
            </el-form-item> -->

            <!-- <el-form-item label="商户名称" prop="name" :span="12">
              <el-input
                v-model="form.name"
                :disabled="true"
                placeholder="填写营业执照上的名称"
              ></el-input>
            </el-form-item> -->
            <!-- <el-form-item label="类型" prop="type">
                <el-col :span="11">
                  <el-select
                    :disabled="true"
                    v-model="form.type"
                    style="width: 100%"
                    filterable
                    clearable
                    placeholder="请选择类型"
                  >
                    <el-option
                      v-for="(option, optionIndex) in typeSelectList"
                      :key="optionIndex"
                      :label="option.label"
                      :value="option.value"
                    />
                  </el-select>
                </el-col>
              </el-form-item>
              <el-form-item label="限制农药经营许可证" prop="limitPic">
                <el-col :span="11">
                  <el-select
                    v-model="form.limitPic"
                    style="width: 100%"
                    filterable
                    clearable
                    @change="ChangeHisLimit"
                    placeholder="请选择是否持有限制农药经营许可证"
                  >
                    <el-option
                      v-for="(option, optionIndex) in limitSelectList"
                      :key="optionIndex"
                      :label="option.label"
                      :value="option.value"
                    />
                  </el-select>
                </el-col>
              </el-form-item>
              <el-form-item label="限用农药经营模式" prop="businessModel">
                <el-col :span="11">
                  <el-select
                    v-model="form.businessModel"
                    style="width: 100%"
                    filterable
                    clearable
                    placeholder="限用农药经营模式"
                  >
                    <el-option
                      v-for="(option, optionIndex) in businessModelSelectList"
                      :key="optionIndex"
                      :label="option.label"
                      :value="option.value"
                    />
                  </el-select>
                </el-col>
              </el-form-item>
              <el-form-item label="地址" prop="address">
                <el-row>
                  <el-col :span="11">
                  <v-distpicker
                  style="width: 100%"
                  :province="select.province"
                  :city="select.city"
                  :area="select.area"
                  @selected="selectcity"
                ></v-distpicker>
                </el-col>
                </el-row>
                <el-row>
                  <el-col :span="11">
                  <el-input
                    style="margin-top: 20px"
                    type="textarea"
                    :rows="2"
                    placeholder="请输入详细地址"
                    v-model="form.address"
                  >
                  </el-input>
                </el-col>
                </el-row>
              </el-form-item> -->
            <!-- <SimpleForm
                ref="imageForm"
                :form="form"
                :rules="rules"
                :formFields="formFields"
                lableWidth="150px"
                :isShowCancel="false"
                :isShowSave="false"
                @imgUploadSuc="imageSuccess"
                @imageRemove="imageRemove"
              /> -->
          </el-form>
        </div>
      </mia-Card>
      <mia-Card title="营业执照">
        <div v-if="form.name" style="margin-left: 4px" slot="content">
          <el-row class="business active">
            <el-col :span="11">
              <el-row>
                <el-col :span="5" class="label"> 企业/单位名称: </el-col>
                <el-col :span="18">
                  {{ form.name }}
                </el-col>
              </el-row>
            </el-col>
            <el-col :span="11">
              <el-row>
                <el-col :span="5" class="label"> 社会统一信用代码: </el-col>
                <el-col :span="18">
                  {{ form.creditCode }}
                </el-col>
              </el-row>
            </el-col>
          </el-row>
          <el-row class="business">
            <el-col :span="11">
              <el-row>
                <el-col :span="5" class="label">
                  <span v-if="form.type === '1'">经营场所</span>
                  <span v-else>住所</span>:
                </el-col>
                <el-col :span="18">
                  {{ form.residence }}
                </el-col>
              </el-row>
            </el-col>
            <el-col :span="11">
              <el-row>
                <el-col :span="5" class="label">
                  <span v-if="form.type === '1'">经营者</span>
                  <span v-else>法人</span>:
                </el-col>
                <el-col :span="18">
                  {{ form.head }}
                </el-col>
              </el-row>
            </el-col>
          </el-row>
          <el-row class="business active">
            <el-col :span="11">
              <el-row v-if="form.type === '1'">
                <el-col :span="5" class="label"> 组织形式: </el-col>
                <el-col :span="18">
                  {{ form.composingForm }}
                </el-col>
              </el-row>
              <el-row v-else>
                <el-col :span="5" class="label"> 注册资本（万元）: </el-col>
                <el-col :span="18">
                  {{ form.capital }}
                </el-col>
              </el-row>
            </el-col>
            <el-col :span="11">
              <el-row>
                <el-col :span="5" class="label">
                  <span v-if="form.type === '1'">注册日期</span>
                  <span v-else>成立日期</span>:
                </el-col>
                <el-col :span="18">
                  {{ form.sendDate }}
                </el-col>
              </el-row>
            </el-col>
          </el-row>
          <el-row class="business">
            <el-col :span="24">
              <el-row>
                <el-col style="width: calc(10% - 6px)" class="label">
                  经营范围:
                </el-col>
                <el-col :span="18">
                  {{ form.businessScope }}
                </el-col>
              </el-row>
            </el-col>
          </el-row>
          <el-row class="business active">
            <el-col :span="24">
              <el-row>
                <el-col style="width: calc(10% - 6px)" class="label">
                  &nbsp;
                </el-col>
                <el-col :span="18">
                  <el-image
                    :preview-src-list="[form.licenseImg]"
                    :src="form.licenseImg"
                    alt=""
                    style="height: 100px"
                    v-if="form.licenseImg"
                  />
                </el-col>
              </el-row>
            </el-col>
          </el-row>
        </div>
      </mia-Card>
      <mia-Card title="联系信息">
        <div style="margin-left: 4px" slot="content">
          <el-form
            :rules="rules"
            ref="InformationForm"
            :model="form"
            :inline="true"
          >
            <el-row>
              <el-col :span="2" class="form-label">
                <span>地址</span>
              </el-col>
              <el-col :span="8">
                <el-input
                  v-model="form.address"
                  :disabled="true"
                  placeholder="请在手机App、小程序录入"
                ></el-input>
              </el-col>
              <el-col :span="2" class="form-label">
                <span>联系人</span>
              </el-col>
              <el-col :span="8">
                <el-input
                  v-model="name"
                  :disabled="true"
                  placeholder="请在手机App、小程序录入"
                ></el-input>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="2" class="form-label">
                <span>门牌号</span>
              </el-col>

              <el-col :span="8">
                <el-input
                  v-model="form.doorPlate"
                  :disabled="true"
                  placeholder="请在手机App、小程序录入"
                ></el-input>
              </el-col>
              <el-col :span="2" class="form-label">
                <span>联系电话</span>
              </el-col>
              <el-col :span="8">
                <el-input
                  v-model="contact"
                  :disabled="true"
                  placeholder="请在手机App、小程序录入"
                ></el-input>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </mia-Card>
    </div>
    <miaDialog ref="changeName" v-if="changeNameVisible">
      <div slot="content">
        <el-form
          ref="changeNameForm"
          :rules="changeNameRules"
          :model="changeNameForm"
          label-width="80px"
        >
          <el-form-item label="商户名称" key="name" prop="name">
            <el-input
              v-model="changeNameForm.name"
              placeholder="请输入商户名称"
            ></el-input>
          </el-form-item>
        </el-form>
        <div class="footer">
          <el-button type="primary" @click="submitChangeName()"
            >提 交</el-button
          >
          <el-button @click="close">取 消</el-button>
        </div>
      </div>
    </miaDialog>
  </div>
</template>
<script>
import { GetCompanyBasic, EditCompanyBasic } from "@/api/system";
import VDistpicker from "v-distpicker";
import setting from "@/until/settings.js";
export default {
  components: {
    VDistpicker,
  },
  data() {
    return {
      loading: false,
      form: {
        master: "",
        contact: "",
        tokenCode: "",
        tokenId: "",
        name: "",
        type: "",
        provinceId: "",
        cityId: "",
        areaId: "",
        province: "",
        city: "",
        area: "",
        address: "",
        limitPic: "",
        businessModel: "",
        licensePic: "",
      },
      select: {},
      // 表单校验
      rules: {
        name: [
          {
            required: true,
            message: "请填写营业执照上的名称",
            trigger: "blur",
          },
          {
            min: 6,
            max: 24,
            message: "名称长度在 6 到 24 个字符",
            trigger: "blur",
          },
        ],
        type: [
          { required: true, message: "请选择店铺类型", trigger: "change" },
        ],
        limitPic: [
          {
            required: true,
            message: "请选择是否持有农药经营许可证",
            trigger: "change",
          },
        ],
        address: [
          { required: true, message: "请输入详细地址", trigger: "blur" },
          {
            min: 1,
            max: 64,
            message: "详细地址长度在 1 到 64 个字符",
            trigger: "blur",
          },
        ],
        businessModel: [
          {
            required: true,
            message: "请选择限用农药经营模式",
            trigger: "change",
          },
        ],
        licensePic: [
          { required: true, message: "请上传营业执照", trigger: "blur" },
        ],
      },
      licensePic: "",
      cityAddress: "",
      formFields: [
        {
          name: "营业执照图片",
          field: "licensePic",
          type: "img",
          imageList: [],
          open: true,
        },
      ],
      typeSelectList: [
        { label: "个体工商户", value: 0 },
        { label: "公司", value: 1 },
      ],
      limitSelectList: [
        { label: "是", value: 0 },
        { label: "否", value: 1 },
      ],
      businessModelSelectList: [
        { label: "专柜经营", value: 0 },
        { label: "定点经营", value: 1 },
      ],
      changeNameForm: {
        name: "",
      },
      changeNameRules: {
        name: [
          { required: true, message: "请填写名称", trigger: "blur" },
          {
            min: 1,
            max: 64,
            message: "名称长度在 1 到 64 个字符",
            trigger: "blur",
          },
        ],
      },
      changeNameVisible: false,
      name: "",
      contact: "",
    };
  },
  created() {
    this.getInformation();
    this.name = sessionStorage.getItem("Name");
    this.contact = sessionStorage.getItem("contact");
  },
  methods: {
    // 获取公司信息
    getInformation() {
      this.loading = true;
      this.formFields[0].imageList = [];
      this.licensePic = [];
      GetCompanyBasic().then((res) => {
        if (res.code === 1) {
          this.form = { ...res.data };
          if (!this.form.limitPic) {
            this.businessModelSelectList = [
              { label: "专柜经营", value: 0 },
              { label: "定点经营", value: 1 },
            ];
          } else {
            this.businessModelSelectList = [
              { label: "无", value: 2 },
              { label: "专柜经营", value: 0 },
            ];
          }
          if (this.form.licensePic !== "") {
            this.formFields[0].imageList.push({
              src: setting.imgUrl + this.form.licensePic,
            });
            this.form.licenseImg = setting.imgUrl + this.form.licensePic;
          }
          this.licensePic = this.formFields[0].imageList;
          this.select.province = this.form.province;
          this.select.city = this.form.city;
          this.select.area = this.form.area;
        } else {
          this.$message.error(res.msg);
        }
        this.loading = false;
      });
    },
    selectcity(data) {
      this.cityAddress = JSON.parse(JSON.stringify(data));
    },
    changeName() {
      this.changeNameVisible = true;
      this.changeNameForm.name = this.form.name;
      this.$nextTick(() => {
        this.$refs.changeName.init("修改商户名称");
      });
    },
    submitChangeName() {
      this.$refs["changeNameForm"].validate((valid) => {
        if (valid) {
          EditCompanyBasic({ ...this.changeNameForm, id: this.form.id }).then(
            (res) => {
              const { data, msg, code } = res;
              if (code === 1) {
                this.$message.success("修改成功");
                this.getInformation();
                this.changeNameVisible = false;
                this.loading = false;
              } else {
                this.$message.success(msg);
                this.loading = false;
              }
            }
          );
          //    resetPhone(this.form).then(res => {
          //        if(res.code === 1) {
          //            this.$message.success('操作成功')
          //            this.close()
          //        } else {
          //            this.$message.error(res.msg)
          //        }
          //    })
        } else {
          return false;
        }
      });
    },
    // 选择是否持有许可证
    ChangeHisLimit(value) {
      // 仅当[限用农药经营许可证]为[是]时 更改选项
      if (!value) {
        this.businessModelSelectList = [
          { label: "专柜经营", value: 0 },
          { label: "定点经营", value: 1 },
        ];
      } else {
        this.businessModelSelectList = [
          { label: "无", value: 2 },
          { label: "专柜经营", value: 0 },
        ];
      }
      this.form.businessModel = "";
      setTimeout(() => {
        this.$refs["InformationForm"].clearValidate(["businessModel"]);
      }, 30);
    },
    // 修改公司信息
    EditCompany() {
      let isImage = false;
      let isForm = false;
      this.$refs["imageForm"].$children[0].validate((valid) => {
        if (valid) {
          isImage = true;
        } else {
          isImage = false;
          return;
        }
      });
      this.$refs["InformationForm"].validate((valid) => {
        if (valid) {
          isForm = true;
        } else {
          isForm = false;
          return;
        }
      });
      if (isImage && isForm) {
        this.loading = true;
        const form = JSON.parse(JSON.stringify(this.form));
        if (this.cityAddress) {
          // 有修改
          form.provinceId = +this.cityAddress.province.code;
          form.cityId = +this.cityAddress.city.code;
          form.areaId = +this.cityAddress.area.code;
          form.province = this.cityAddress.province.value;
          form.city = this.cityAddress.city.value;
          form.area = this.cityAddress.area.value;
        }
        EditCompanyBasic(form).then((res) => {
          const { data, msg, code } = res;
          if (code === 1) {
            this.$message.success("修改成功");
            this.getInformation();
            this.loading = false;
          } else {
            this.$message.success(msg);
            this.loading = false;
          }
        });
      }
    },
    // 上传图片
    imageSuccess(file, list, index, field) {
      this.form[field] = file.response.url;
      this.formFields[index].imageList = list;
      if (field === "licensePic") {
        this.licensePic = list;
      }
    },
    // 图片移除
    imageRemove(field, index, n) {
      const arr = JSON.parse(JSON.stringify(this.formFields));
      arr[index].imageList.splice(n, 1);
      if (field === "licensePic") {
        this.licensePic.splice(n, 1);
      }
      this.formFields = arr;
      this.form[field] = "";
    },
    close() {
      this.$nextTick(() => {
        this.changeNameVisible = false;
        this.getInformation();
      });
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .distpicker-address-wrapper {
  display: inline-flex;
  width: 100%;
  justify-content: center;
  flex-wrap: nowrap;
  label {
    // width: 32.5%;
    width: 100%;
    select {
      width: 100%;
    }
  }
}
.content {
  width: 100%;
}
.business {
  padding: 15px 0px 15px 20px;
  &.active {
    background-color: #f9fbff;
  }
  .label {
    text-align: right;
    margin-right: 10px;
  }
}
.form-label {
  text-align: center;
  line-height: 45px;
}
.footer {
  text-align: center;
}
/deep/ .el-input.is-disabled .el-input__inner {
  color: #333;
}
</style>
